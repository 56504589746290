import {
  CSSProperties,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { MdInfo } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import ReactDOM from 'react-dom';

import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';

import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { useIsMounted } from '~/common/hooks/use-is-mounted';
import { useClickOutside } from '~/common/hooks/use-click-outside';

import { Container, Content, Header, ModalSize } from './styles';
import { Text } from '../Text';

export type OnCloseModal = (
  event?: MouseEvent<HTMLButtonElement, MouseEvent>
) => void;

export interface ModalProps {
  onClose?: OnCloseModal;
  contentStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  backdropStyle?: CSSProperties;
  title?: string;
  titleInfo?: string;
  size?: ModalSize;
  contentId?: string;
  backdropId?: string;
  renderInPortal?: boolean;
  closeOnClickOutside?: boolean;
  showHeader?: boolean;
  closeOnEsc?: boolean;
  closeOnX?: boolean;
  contentHeight?: string;
  rotate?: boolean;
}

export function closeModal(
  onClose?: OnCloseModal,
  contentId = 'modal-content',
  backdropId = 'modal-backdrop'
): void {
  const modalContent = document.getElementById(contentId);
  const modalBackdrop = document.getElementById(backdropId);

  if (modalContent && modalBackdrop) {
    modalContent.style.transform = 'translateY(1000px)';
    modalBackdrop.style.opacity = '0';
  }

  setTimeout(() => {
    onClose && onClose();
  }, 300);
}

export const Modal: IFCWithChildren<ModalProps> = ({
  onClose,
  children,
  backdropStyle,
  contentStyle,
  headerStyle,
  size = 'md',
  titleInfo = undefined,
  title,
  renderInPortal = true,
  closeOnClickOutside = true,
  contentId = 'modal-content',
  backdropId = 'modal-backdrop',
  showHeader = true,
  closeOnEsc = true,
  closeOnX = true,
  rotate = false,
  contentHeight,
}) => {
  const theme = useTheme();
  const backdropRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isMounted = useIsMounted();

  const handleClose = useCallback(() => {
    closeModal(onClose, contentId, backdropId);
  }, [backdropId, contentId, onClose]);

  const modalContentRef = useClickOutside<HTMLDivElement>(
    handleClose,
    closeOnClickOutside
  );

  useEffect(() => {
    const closeWithEsc = (event: any): void => {
      if (closeOnEsc) {
        if (event.keyCode === 27) {
          handleClose();
        }
      }
    };

    document.addEventListener('keydown', closeWithEsc, false);

    return () => {
      document.removeEventListener('keydown', closeWithEsc, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Element = useMemo(() => {
    return (
      <Container ref={backdropRef} style={backdropStyle} id={backdropId}>
        <Content
          ref={contentRef}
          rotate={rotate}
          size={size}
          id={contentId}
          killMaxHeight={!!contentHeight}
          {...(contentHeight && {
            style: {
              height: contentHeight,
            },
          })}
        >
          <div>
            <div ref={modalContentRef} style={contentStyle}>
              {showHeader && (
                <Header style={headerStyle}>
                  <Text bold size="md">
                    {title}
                  </Text>

                  {titleInfo && (
                    <Tooltip title={titleInfo} arrow>
                      <div style={{ marginRight: 'auto', marginTop: '2px' }}>
                        <MdInfo size={20} color={theme.text} />
                      </div>
                    </Tooltip>
                  )}

                  {closeOnX && (
                    <button type="button" onClick={handleClose}>
                      <IoClose />
                    </button>
                  )}
                </Header>
              )}

              {children}
            </div>
          </div>
        </Content>
      </Container>
    );
  }, [
    backdropId,
    backdropStyle,
    titleInfo,
    children,
    closeOnX,
    contentId,
    contentStyle,
    contentHeight,
    handleClose,
    headerStyle,
    modalContentRef,
    showHeader,
    size,
    title,
    rotate,
  ]);

  if (!isMounted) return null;

  if (!renderInPortal) return Element;

  return ReactDOM.createPortal(Element, document.getElementById('modal-root'));
};

import { FC } from 'react';

import { UseTermsModalContainer } from './styles';

export interface IUseTermsModalProps {
  readonly reverse?: boolean;
  readonly withoutContact?: boolean;
}
export const UseTermsModal: FC<IUseTermsModalProps> = ({
  reverse,
  withoutContact,
}) => {
  return (
    <UseTermsModalContainer reverse={reverse}>
      <div>
        <h2>Termos de Uso</h2>
        <hr />
      </div>

      <div>
        <h1>Introdução</h1>
        <span>
          Seja bem-vindo ao CryptoControl. Ao utilizar nosso sistema, você está
          concordando com os Termos de Uso abaixo. Por favor, leia atentamente
          para entender suas responsabilidades e os nossos compromissos.
        </span>
      </div>

      <div>
        <h1>Aceitação dos Termos</h1>
        <span>
          Ao acessar e utilizar nosso sistema, você concorda em seguir e estar
          sujeito a estes Termos de Uso. Caso não concorde com algum dos termos,
          recomendamos que não utilize nosso sistema.
        </span>
      </div>

      <div>
        <h1>Uso do Sistema</h1>
        <span>
          Nosso sistema oferece ferramentas para o gerenciamento de criptomoedas
          e indicadores, incluindo análises de dados, gráficos e alertas
          personalizados. No entanto, não fornecemos recomendações de compra ou
          venda de criptomoedas.
        </span>
      </div>

      <div>
        <h1>Responsabilidade do Usuário</h1>
        <span>
          Como usuário, você é plenamente responsável por todas as decisões de
          investimento que tomar. Qualquer ação de compra ou venda de
          criptomoedas baseada nas informações fornecidas pelo nosso sistema é
          de sua total responsabilidade. Não nos responsabilizamos por eventuais
          perdas ou danos decorrentes de suas decisões de investimento.
        </span>
      </div>

      <div>
        <h1>Limitação de Responsabilidade</h1>
        <span>
          Nos empenhamos para garantir a precisão e a atualização das
          informações fornecidas pelo nosso sistema. No entanto, pode haver
          momentos em que ajustes nas informações sejam necessários. Estamos
          comprometidos em corrigi-los e analisá-los o mais rapidamente
          possível.
        </span>
      </div>

      <div>
        <h1>Sem Consultoria Financeira</h1>
        <span>
          Nosso sistema não oferece consultoria financeira, jurídica ou de
          investimento. As informações fornecidas têm caráter informativo e
          educacional e não constituem conselhos de compra ou venda de qualquer
          criptomoeda.
        </span>
      </div>

      <div>
        <h1>Manutenção e Atualizações</h1>
        <span>
          O sistema pode passar por interrupções devido a manutenção ou
          atualizações. Esforçamo-nos para minimizar qualquer inconveniente, mas
          não podemos nos responsabilizar por eventuais transtornos ou perdas
          decorrentes dessas interrupções. Atualizações podem alterar algumas
          funcionalidades, e, quando necessário, orientaremos sobre as novas
          funções. Algumas telas ou funções podem ser modificadas ou desativadas
          conforme nossa estratégia de produto, e os usuários serão devidamente
          comunicados.
        </span>
      </div>

      <div>
        <h1>Alterações nos Termos</h1>
        <span>
          Podemos modificar estes Termos de Uso a qualquer momento. As
          alterações entrarão em vigor imediatamente após a publicação no site.
          O uso contínuo do sistema após a publicação das alterações será
          considerado como aceitação das mudanças.
        </span>
      </div>

      <div>
        <h1>Rescisão</h1>
        <span>
          Reservamo-nos o direito de suspender ou encerrar o acesso ao nosso
          sistema, sem aviso prévio, caso os Termos de Uso sejam violados.
        </span>
      </div>

      <div>
        <h1>Propriedade Intelectual</h1>
        <span>
          Todo o conteúdo, software e outros materiais disponíveis em nosso
          sistema são de nossa propriedade ou licenciados para nós e estão
          protegidos por leis de propriedade intelectual. O acesso ao sistema é
          pessoal e intransferível, e o compartilhamento de login é estritamente
          proibido.
        </span>
      </div>

      <div>
        <h1>Jurisdição e Lei Aplicável</h1>
        <span>
          Estes Termos de Uso são regidos e interpretados de acordo com as leis
          do Brasil. Qualquer disputa decorrente destes termos será resolvida
          exclusivamente nos tribunais competentes de São Paulo.
        </span>
      </div>

      <div>
        <h1>Assinatura, Cancelamento e Reembolso:</h1>
        <span>
          Ao assinar nossos serviços, você terá acesso completo por 30 dias
          corridos, contados a partir da data de pagamento. Caso opte pelo
          cancelamento antes do final desse período, o serviço continuará
          disponível até o término dos 30 dias, sem reembolso proporcional.
        </span>
        <br />
        <span>
          Caso seja sua primeira assinatura, oferecemos 7 dias gratuitos no plano Platinum.
          Durante este período, você pode explorar todas as funcionalidades sem custo. Se o
          cancelamento não for realizado até o fim dos 7 dias, a cobrança mensal
          será iniciada automaticamente, com renovações a cada 30 dias.
        </span>
      </div>

      <div>
        <h1>Política de Cancelamento e Reembolso:</h1>

        <span>
          Cancelamentos realizados antes do final do ciclo de 30 dias não são
          elegíveis para reembolso parcial. Recomendamos cancelar antes da
          renovação automática para evitar cobranças futuras.
        </span>
        <br />
        <span>{`O processo de cancelamento é simples e pode ser feito diretamente em sua conta, acessando Configurações > Aba de Assinatura`}</span>
      </div>

      {!withoutContact && (
        <div>
          <h1>Contato</h1>
          <span>
            Se você tiver dúvidas sobre estes Termos de Uso, entre em contato
            conosco através do e-mail <b>contact@cryptocontrol.pro</b>
          </span>
        </div>
      )}

      <div>
        <h1>Data de Vigência</h1>
        <span>Estes Termos de Uso entram em vigor a partir de 01/01/2023.</span>
      </div>
    </UseTermsModalContainer>
  );
};

import { FC, useMemo } from 'react';

import { isIOS } from 'react-device-detect';
import CurrencyInputField from 'react-currency-input-field';

import { CurrencyTypesEnum } from '~/common/enums/currency-types-enum';
import { is } from 'date-fns/locale';

interface ICurrencyInputProps {
  name: string;
  value: number;
  currency?: CurrencyTypesEnum;
  onChange: (name: string, value: string | number) => void;
}
export const CurrencyInput: FC<ICurrencyInputProps> = ({
  name,
  value,
  currency = CurrencyTypesEnum.US,
  onChange,
}) => {
  const intlConfig = useMemo(
    () =>
      currency === CurrencyTypesEnum.BR
        ? { locale: 'pt-BR', currency: 'BRL' }
        : currency === CurrencyTypesEnum.US
        ? { locale: 'en-US', currency: 'USD' }
        : { locale: 'pt-BR', currency: 'EUR' },
    [currency]
  );

  const transformRawValueiOS = (iosValue: string): string => {
    return iosValue.toString().replaceAll(',', '.');
  };

  const formatValue = (value: number | string): string => {
    let isBrasilian = false;

    if (!value || value == 0) {
      return value?.toString();
    } else if (value.toString().includes(',')) {
      isBrasilian = true;
      value = value.toString().replace(/,/g, '.');
    }

    let valueFloat = 0;

    if (typeof value === 'string') {
      valueFloat = parseFloat(value);
    } else {
      valueFloat = value;
    }

    if (value.toString().includes('.') && value.toString().split('.')[1] === '') {
      if (isBrasilian) {
        return value.toString().replace('.', ',');
      } else {
        return value.toString();
      }
    }

    if ((value.toString().endsWith('.0') || value.toString().endsWith('.00'))) {
      if (valueFloat >= 2 && value.toString().endsWith('.0')) {
        return value.toString() + '0';
      }
      
      return value.toString();
    }

    if (valueFloat >= 2 && valueFloat % 1 === 0 && !value.toString().includes('.')) {
      return valueFloat.toFixed(0);
    }

    if (valueFloat < 2) {
      if (valueFloat.toString().includes('e')) {
        return valueFloat.toFixed(12);
      }
      return value.toString();
    } else {
      return valueFloat.toFixed(2).toString().includes('.') ? valueFloat.toFixed(2) : valueFloat.toFixed(2) + '.00';
    }
  };

  return (
    <CurrencyInputField
      name={name}
      value={formatValue(value)}
      decimalsLimit={12}
      onValueChange={onChange}
      intlConfig={intlConfig}
      allowNegativeValue={false}
      {...(isIOS && {
        decimalSeparator: '.',
        groupSeparator: ',',
        disableGroupSeparators: true,
        transformRawValue: transformRawValueiOS,
      })}
    />
  );
};

import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';

import { GrDiamond } from 'react-icons/gr';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';

import { themes } from '~/common/styles/themes';
import { useConfigurations } from '~/common/providers/subscription';
import { useSidebar } from '~/common/providers/sidebar';
import { useAuth } from '~/common/providers/auth';
import { ISidebarData } from '~/common/interfaces/i-sidebar-data';
import { UserSubscriptionTypes } from '~/common/enums/user-subscription-types.enum';
import { Text } from '~/common/components/Text';

import {
  Arrow,
  NavContainer,
  NavWrapper,
  MenuBadge,
  SubmenuContainer,
} from './styles';

interface IBaseNavOptionProps {
  readonly submenu?: boolean;
  readonly setSubMenuOpen?: Dispatch<SetStateAction<string>>;
  readonly isSubMenuOpen?: boolean;
  readonly isOpenOnScreen?: boolean;
  readonly collapsed?: boolean;
  readonly highlight?: boolean;
  readonly nav: ISidebarData & {
    readonly parent?: string;
  };
}
export const BaseNavOption: FC<IBaseNavOptionProps> = ({
  submenu = false,
  isSubMenuOpen = false,
  isOpenOnScreen = false,
  highlight = false,
  collapsed = false,
  setSubMenuOpen,
  nav: {
    icon: Icon,
    iconColor = 'text',
    onClick,
    label,
    tag,
    route,
    blankRoute,
    parent,
    children,
    allowedOnlyFor,
    subchildren,
    isNew,
    platinum,
    soon,
  },
}) => {
  const router = useRouter();
  const { user } = useAuth();
  const { subscription } = useConfigurations();
  const { current, collapseMenuState, handleCollapseMenu } = useSidebar();
  const theme = useTheme();

  const checkSubMenuOpenOnScreen = useCallback(
    (_tag: string): boolean => {
      if (!isOpenOnScreen) return false;
      const { asPath } = router;
      const split = asPath.split('/');
      const pathTag = split[split.length - 1];
      const toCompare = tag !== pathTag ? `${tag}-${pathTag}` : pathTag;
      return toCompare === _tag;
    },
    [router, tag, isOpenOnScreen]
  );

  const allowed = useMemo(() => {
    if (!allowedOnlyFor || !allowedOnlyFor.length) {
      return true;
    }

    if (!user) {
      return false;
    }

    const { id } = user;
    const toNum = Number(id);
    return allowedOnlyFor.includes(toNum);
  }, [user, allowedOnlyFor]);

  const isMenu = useMemo(
    () => !parent && children?.length > 0,
    [parent, children]
  );

  const isSubmenu = useMemo(() => subchildren?.length > 0, [subchildren]);

  const submenuHeight = useMemo(
    () =>
      !subchildren || subchildren.length === 0
        ? 0
        : 10 + subchildren?.length * 50,
    [subchildren]
  );

  const Element = useMemo(
    () => (
      <NavContainer
        soon={!allowed}
        collapsed={collapsed}
        highlight={highlight}
        selected={(route && route === current) || isOpenOnScreen}
        isSubMenu={submenu}
        isMenu={isMenu}
        {...(onClick &&
          !submenu && {
            onClick,
          })}
        {...(!parent &&
          children?.length > 0 && {
            onClick: () => handleCollapseMenu(tag),
          })}
        {...(submenu && {
          style: {
            paddingLeft: '10px',
          },
        })}
      >
        <Tooltip arrow title={!collapsed ? null : label}>
          <div id="svg-wrapper">
            <Icon color={theme[iconColor]} />
          </div>
        </Tooltip>

        {!collapsed && <Text size="sm">{label}</Text>}
        {!collapsed && (isMenu || isSubmenu) && (
          <Arrow
            size={18}
            color={theme.text}
            id="menu-arrow"
            open={!!collapseMenuState[tag] || isSubMenuOpen}
            {...(isSubmenu && {
              style: {
                opacity: 0.35,
              },
            })}
          />
        )}
      </NavContainer>
    ),
    [
      allowed,
      highlight,
      route,
      current,
      isOpenOnScreen,
      submenu,
      isMenu,
      onClick,
      parent,
      children?.length,
      Icon,
      theme,
      iconColor,
      label,
      isSubmenu,
      collapseMenuState,
      tag,
      isSubMenuOpen,
      collapsed,
      handleCollapseMenu,
    ]
  );

  return (
    <NavWrapper
      {...(subchildren?.length > 0 && {
        onClick: () => setSubMenuOpen((prev) => (prev ? null : tag)),
      })}
    >
      {route && allowed ? (
        <Link
          href={route}
          passHref
          {...(blankRoute && {
            target: '_blank',
          })}
        >
          {Element}
        </Link>
      ) : (
        Element
      )}

      {!collapsed && !allowed && (
        <MenuBadge
          style={{
            backgroundColor: themes.dark.orange,
            opacity: 0.75,
            width: '80px',
          }}
        >
          <Text bold>em breve</Text>
        </MenuBadge>
      )}

      {!collapsed && isNew && (
        <MenuBadge
          style={{ backgroundColor: themes.dark.green, width: '50px' }}
        >
          <Text bold>novo</Text>
        </MenuBadge>
      )}

      {!collapsed &&
        platinum &&
        subscription !== UserSubscriptionTypes.platinum &&
        ![UserSubscriptionTypes.admin, UserSubscriptionTypes.vip].includes(
          subscription
        ) && (
          <Tooltip arrow title="Assinatura platinum requerida">
            <MenuBadge
              style={{ backgroundColor: theme.background3, width: '30px' }}
            >
              <GrDiamond color={theme.light_blue} size={20} />
            </MenuBadge>
          </Tooltip>
        )}

      {!collapsed && soon && (
        <MenuBadge
          style={{ backgroundColor: theme.background3, width: '80px' }}
        >
          <Text bold color="white">
            em breve
          </Text>
        </MenuBadge>
      )}

      {subchildren?.length > 0 && (
        <SubmenuContainer height={submenuHeight} open={isSubMenuOpen}>
          {subchildren?.map((_submenu) => (
            <BaseNavOption
              submenu
              collapsed={collapsed}
              isOpenOnScreen={checkSubMenuOpenOnScreen(_submenu.tag)}
              key={_submenu.tag}
              nav={{
                ..._submenu,
                parent: _submenu.tag,
              }}
            />
          ))}
        </SubmenuContainer>
      )}
    </NavWrapper>
  );
};

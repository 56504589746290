/* eslint-disable jsx-a11y/alt-text */
import { useInsertionEffect } from 'react';

import Image from 'next/image';

import { useTheme } from '~/common/providers/theme';
import { useAuth } from '~/common/providers/auth';
import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { useMediaQuery } from '~/common/hooks/use-media-query';
import { Text } from '~/common/components/Text';

import { AuthBox, PublicLayoutContainer } from './styles';
import cclogo from '../../../public/images/logo-home.png';
import mobileBackground from '../../../public/images/login-background-mobile.png';
import desktopBackground from '../../../public/images/login-background-desktop.png';

export const PublicLayuout: IFCWithChildren = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const { setTheme } = useTheme();
  const { loggedIn } = useAuth();
  useInsertionEffect(() => {
    setTheme('light');
  }, []);

  return (
    <PublicLayoutContainer>
      <Image
        draggable={false}
        id="background-image"
        alt="logo"
        src={isMobile ? mobileBackground : desktopBackground}
      />

      <Image
        draggable={false}
        id="cc-logo"
        src={cclogo}
        alt="logo"
        width={300}
      />
      {loggedIn ? null : <AuthBox>{children}</AuthBox>}
      <Text color="text_reverse" bold size="sm" style={{ opacity: 0.25 }}>
        © 2024 CRYPTOCONTROLLABS LTDA - 52.905.625/0001-04
      </Text>
    </PublicLayoutContainer>
  );
};
